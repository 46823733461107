import React, { useEffect} from "react"
import {navigate, graphql} from "gatsby";
import {useIsMobile} from "../utils";
import Story from "../components/story";
import Contact from "../components/contact";
import Hero from "../components/hero";
import {Product} from "../models/product";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { SEO } from '../components/seo';

const News = ({ news }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexFlow: 'wrap',
        gap: '64px',
        columnGap: '64px',
        rowGap: '64px',
        padding: '0px 24px'
      }}
    >
      {
        news.map((v) => (
          <NewsItem value={v} />
        ))
      }
    </div>
  );
}

const NewsItem = ({ value }) => {
  const { tags, name, coverUrl, description, link } = value;
  const { isMobile } = useIsMobile();
    const Picture = () => <img src={coverUrl} style={{
        ...(
            isMobile ? {
                width: '100%',
            } : {
                width: 'calc(50% - 32px)',
            }
        ),
    }} />;
  const Description = () =>
      <div style={{ display: 'flex', flexDirection: 'column',
        ...(
          tags.includes('首頁公告-橫') && !isMobile ? {
                width: 'calc(50% - 32px)',
          } : {}
        )
      }}
       >
        { name && <div className="title2" style={{ lineHeight: '36px' }}>
              {name}
          </div>}
          <div style={{ height: 24, fontWeight: 'bold', textAlign: 'justify'}}/>
          <div className="body1">
            <ReactMarkdown 
              rehypePlugins={[rehypeRaw]}
              components={{
                p: (props) => <div className="body1" style={{ margin: '1.625px 0px', textAlign: 'justify' }} {...props} />
              }}
              children={description} />
          </div>
          <div 
            className="my-btn"
            style={{
              display: 'flex',
              padding: '8px 48px',
              justifyContent: 'center',
              border: '1px solid',
              margin: '24px auto',
              marginLeft: 0,
              borderRadius: 8,
              cursor: "pointer",
              lineHeight: '24px',
          }} onClick={() => {
            navigate(link);
          }}>
            了解更多
          </div>
      </div>
  if (tags.includes('首頁公告-直') || isMobile) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', flexDirection: 'column', flexGrow: 1, maxWidth: isMobile ? '100%' : 'calc(50% - 32px)'}}>
        <img src={coverUrl} style={{
            width: '100%',
        }} />
        <div style={{ height: 24}}></div>
        <Description />
      </div>
    )
  }
  return (
    <div style={{ width: '100%', display: 'flex', gap: 64, justifyContent: 'center', alignItems: 'center'}}>
      <Picture  />
      <Description />
    </div>
  )
}

const MonthlyCoffee = ({ english, name, content, imageUrl, onClick }) => {
  const { isMobile } = useIsMobile();
  return (
    <div
    style={{ 
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
        width: isMobile ? 200 : 300,
    }}
  >
    <img
      src={imageUrl}
      style={{ 
        cursor: 'pointer',
        width: isMobile ? 200 : 300,
        height: isMobile ? 200 : 300,
        objectFit: 'cover',
        aspectRatio: '1 / 1'
      }}
      onClick={onClick}
    />
    <div style={{ height: 24 }}></div>
    <div 
        className="body2"
        style={{ width: '100%', textAlign: 'center',
          overflow: "hidden",
        }}>{name}</div>
    <div style={{ height: 4 }}></div>
    <div 
        className="body1"
        style={{ textAlign: 'center', width: '100%', }}>{english}</div>
    <div style={{ height: 16 }}></div>
    <div
      style={{ maxWidth: 300 }}
    >
      <ReactMarkdown 
        rehypePlugins={[rehypeRaw]}
        components={{
          p: (props) => <div style={{ margin: '1.625px 0px', textAlign: 'justify' }} {...props} />
        }}
        children={content} />
    </div>
  </div>
);
}

const BlogIndex = ({
                       data,
                       pageContext: {nextPagePath, previousPagePath},
                   }) => {
    const { isMobile } = useIsMobile();
    const { shopify } = data;
    const monthly = shopify.monthly.edges.map(Product.fromShopify).slice(0,3);
    const specialty = shopify.specialty.edges.map(Product.fromShopify).slice(0,3);
    const news = shopify.news.edges.map(Product.fromShopify);

    return (
        <div>
            <div
                style={{
                    display: `flex`,
                    flexDirection: `column`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    width: `100%`,
                }}
            >
                <Hero 
									images={[
										'https://cdn.shopify.com/s/files/1/0645/0436/5310/files/index-hero-1.jpg',
										'https://cdn.shopify.com/s/files/1/0645/0436/5310/files/index-hero-2.jpg'
									]}
								>
                  <div style={{ 
                    display: `flex`,
                      flexDirection: `column`,
                      alignItems: `center`,
										zIndex: 50,
                  }}>
                    <div
                        id="game-btn"
                        onClick={() => {
                            navigate('/shop');
                        }}
                    >
                        <div style={{ marginTop: -4 }}>
                          立即選購
                        </div>
                    </div>
                  </div>

                </Hero>
                <div
                    style={{
                        padding: '4rem 4rem',
                        maxWidth: 732,
                        textAlign: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <p 
                      className="title3"
                      style={{
                        margin: 0
                      }}
                    >
                      Cooperation Coffee
                    </p>
                    <div className="title1">
                        合作咖啡
                    </div>
                    <div 
                      className="body1"
                      style={{textAlign: 'justify', padding: isMobile ? '0 16px': '64px 0'}}>
                      Cooperation Coffee 是一家位於台灣的咖啡公司。合作咖啡成立於 2017 年，我們從網路販賣咖啡豆開始。我們希望為客人推薦最符合個人口味的咖啡豆，有任何問題都歡迎聯繫我們！
                    </div>
                </div>
            </div>
            <br/>
            <div
              style={{
                margin: '0px auto',
                maxWidth: 1300
              }}
            >
              {
                monthly.length > 0 && <>
                  <div 
              className="title2"
              style={{ margin: '0px auto', width: '100%', textAlign: 'center' }}>
                    每月推薦
                  </div>
                  {/* <div
                    className="body1"
                    style={{ margin: '0px auto', width: '100%', textAlign: 'center' }}
                  >
                    特殊處理法咖啡豆
                  </div> */}
                </>
              }
              <div style={{
                padding: '64px 64px 128px 64px',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                gap: 24,
                justifyContent: 'space-around',
                alignItems: isMobile ? 'center' : 'start',
              }}>
                {
                  monthly.map((p) => ({
                    name: p.name,
                    english: p.engName,
                    content: p.shortDescription,
                    imageUrl: p.coverUrl,
                    onClick: () => {
                        navigate(`/products/${p.id}`);
                    }
                  })).map((c) => <MonthlyCoffee {...c}
                    />)
                }
              </div>
              <News news={news.filter(({ tags }) => 
                tags.some(v => 
                ['首頁公告-1','首頁公告-2','首頁公告-3'].includes(v))
              )} />
              {
                specialty.length > 0 && <>
                  <div style={{ height: 128 }} />
                  <div 
              className="title2"
              style={{ margin: '0px auto', width: '100%', textAlign: 'center' }}>
                    莊園推薦
                  </div>
                  <div
                    className="body1"
                    style={{ margin: '0px auto', width: '100%', textAlign: 'center' }}
                  >
                    世界冠軍莊園咖啡豆
                  </div>
                </>
              }
              <div style={{
                padding: '64px 64px 128px 64px',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                gap: 24,
                justifyContent: 'space-around',
                alignItems: isMobile ? 'center' : 'start',
              }}>
                {
                  specialty.map((p) => ({
                    name: p.name,
                    english: p.engName,
                    content: p.shortDescription,
                    imageUrl: p.coverUrl,
                    onClick: () => {
                        navigate(`/products/${p.id}`);
                    }
                  })).map((c) => <MonthlyCoffee {...c}
                    />)
                }
              </div>
              <News news={news.filter(({ tags }) => 
                tags.some(v => 
                ['首頁公告-4'].includes(v))
              )} />
              <div style={{ height: 128}} />
            </div>
            <Contact />
            <div
              id="game-btn-ng"
              style={{
              }}
              onClick={() => {
                navigate('/game');
              }}
            >
              咖啡豆
              <br />
              小遊戲
            </div>

        </div>
    )
}

export const query = graphql`{ 
  shopify{
    specialty: products(first: 3, query: "tag:莊園推薦") {
     edges {
       node {
         handle
         title
         featuredImage {
           url
         }
         metafields(identifiers:[{ key: "eng_name", namespace: "custom"}]) {
           value
           key
           reference {
             ...on SHOPIFY_MediaImage{
               image {
                 url
               }
             }
           }
         }
         images(first: 10) {
           edges {
             node {
               url(transform: { 
                 crop: CENTER
                 maxWidth: 500,
                 maxHeight: 500})
               id
               thumbnailUrl: url(transform: { 
                 crop: CENTER
                 maxWidth: 360,
                 maxHeight: 360})
             }
           }
         }
       }
     }
    }
    monthly: products(first: 3, query: "tag:每月推薦") {
     edges {
       node {
         handle
         title
         featuredImage {
           url
         }
         metafields(identifiers:[{ key: "eng_name", namespace: "custom"}]) {
           value
           key
           reference {
             ...on SHOPIFY_MediaImage{
               image {
                 url
               }
             }
           }
         }
         images(first: 10) {
           edges {
             node {
               url(transform: { 
                 crop: CENTER
                 maxWidth: 500,
                 maxHeight: 500})
               id
               thumbnailUrl: url(transform: { 
                 crop: CENTER
                 maxWidth: 360,
                 maxHeight: 360})
             }
           }
         }
       }
     }
    }
    news: products(first: 30, query: "product_type:首頁公告") {
      edges {
        node {
          title
          featuredImage {
            url
          }
          tags
          descriptionHtml
          metafields(identifiers:[{ key: "_link", namespace: "custom"}]) {
            value
            key
            reference {
              ...on SHOPIFY_MediaImage{
                image {
                  url
                }
              }
            }
          }
          images(first: 10) {
            edges {
              node {
                url(transform: { 
                  crop: CENTER
                  maxWidth: 1500,
                  maxHeight: 1000})
                id
                thumbnailUrl: url(transform: { 
                  crop: CENTER
                  maxWidth: 360,
                  maxHeight: 360})
              }
            }
          }
        }
      }
    }
  }
}`;

export default BlogIndex;
export const Head = () => (
  <SEO
    title="合作咖啡"
    description="Cooperation Coffee 是一家位於台灣的咖啡公司。合作咖啡成立於 2017 年，我們從網路販售咖啡豆開始。一直到 2019 年我們有了第一家實體的咖啡店，店鋪座落在台灣的台中市南區。"
    image="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/index-hero-2.jpg"
  >
    <script type="application/ld+json">
    {`{
      "@context" : "https://schema.org",
      "@type" : "WebSite",
      "name" : "合作咖啡",
      "alternateName" : "Cooperation Coffee",
      "url" : "https://cooperation.coffee/"
    }`}
    </script>
  </SEO>
)
