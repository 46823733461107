import React, { useState, useEffect, useMemo, useContext } from "react"
import { Link, navigate } from "gatsby"
import queryString from "query-string";
import { CartContext } from "../cart"
import {Radio } from "../components/radio";
import { useIsMobile } from "../utils"
import taiwanDistricts from "../taiwan-districts.json"
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { client } from "../client"
import LoadingIcon from '../components/loading';
import { toast } from 'react-toastify';
import { numberWithCommas } from '../utils';
import { SEO } from '../components/seo';
import { LayoutContext } from '../components/layout';
import countriesData from '../../static/countries.json';

const Divider = () => (
  <hr
    style={{
      border: "none",
      opacity: 1,
      color: "black",
      background: "black",
      height: 1,
      width: "100%",
    }}
  />
)

const Input = ({
  placeholder = "",
  prefix = "",
  value,
  onChange,
  autoComplete,
}) => (
  <div
    style={{ height: 42, width: '100%', position: 'relative' }}
  >
    {
      prefix && <span
        style={{
          position: 'absolute',
          left: 12,
          top: 8,
          paddingRight: '8px',
          borderRight: '1px solid #d7d5d3',
        }}
      >
        {prefix}
      </span>
    }
    <input
      placeholder={placeholder}
      style={{
        background: "white",
        border: "1px solid #d7d5d3",
        borderRadius: 4,
        padding: '4px 12px',
        flexGrow: 1,
        color: "black",
        minWidth: 0,
        width: '100%',
        height: '100%',
        ...(prefix && {
          paddingLeft: `calc(${prefix.length * 8}px + 32px)`,
        })
      }}
      type="text"
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
)

const Summary = ({ 
  items, 
  itemsPrice, 
  shipping, 
  shippingPrice, 
  shippingDiscount,
  paymentDue,
  totalPrice,
  onApply 
}) => {
  const { isMobile } = useIsMobile();
  const [coupon, setCoupon] = useState("");
  const applyDiscount = () => {
    onApply(coupon);
    setCoupon("");
  }

  return (
    <div
      style={{
        padding: '48px 0px 0px 36px',
        ...(isMobile && { 
          padding: 0,
          margin: '0px -24px 24px -24px'
        }),
        background: "#D2C0AE",
        position: "relative",
        zIndex: 2,
      }}
    >
      <div
        style={{
          color: "black",
          width: '500px',
          ...(isMobile
            ? {
                width: "100%",
                padding: "24px",
              }
            : {
          minHeight: 'calc(100vh - 247px)',
          height: 'auto',
            }),
        }}
      >
        {items.map(e => (
          <div
            key={e.id}
            style={{
              margin: "1rem",
              marginLeft: 0,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: '100%',
            }}
          >
            <div
              style={{
                position: "relative",
              }}
              onClick={() => {
                navigate(e.url)
              }}
            >
              <img
                style={{
                  width: 80,
                  height: 80,
                  cursor: "pointer",
                  borderRadius: 8,
                }}
                src={e.imageUrl}
              />
              <div style={{
                position: 'absolute',
                background: 'black',
                color: 'white',
                textAlign: 'center',
                borderRadius: '50%',
                width: 18,
                height: 18,
                top: -10,
                right: -10,
                fontSize: 12,
              }}>{e.count}</div>
            </div>
            <div
              style={{
                width: 32,
              }}
            ></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: '1',
              }}
            >
              <div
                className="body2"
                onClick={() => {
                  navigate(e.url)
                }}
                style={{
                  fontWeight: "bold",
                  cursor: "pointer",
                  fontSize: 14,
                }}
              >
                {e.title} - {e.spec}
              </div>
              {e.engName && <div className="body1">{e.engName}</div>}
              {e.customAttributes.length > 0 && (
                <div>
                  {e.customAttributes
                    .map(e => `${e.key}: ${e.value}`)
                    .join(",")}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
              </div>
            </div>
            <div style={{ marginLeft: 32}}>{`\$${numberWithCommas(e.price)}`}</div>
          </div>
        ))}
        <Divider />
        <div style={{ display: "flex", gap: 8, flexDirection: "column" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            小計
            <div>NT$ {numberWithCommas(itemsPrice)}</div>
          </div>
          {
            shipping && 
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                運費
                <div>NT$ {numberWithCommas(shippingPrice)}</div>
              </div>
          }
          <div style={{ display: "flex", gap: 8, margin: "8px 0px" }}>
            <Input
              placeholder="折扣碼"
              value={coupon}
              onChange={e => setCoupon(e.target.value)}
            />
            <div style={{ minWidth: 100 }}>
              <div
                className="my-btn"
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  background: "white",
                  borderRadius: 4,
                  border: '1px solid #d7d5d3',
                  color: "black",
                  whiteSpace: "nowrap",
                  height: 42,
                  justifyContent: "center",
                }}
                onClick={() => {
                  applyDiscount()
                }}
              >
                套用
              </div>
            </div>
          </div>
        </div>
        <Divider />
        {paymentDue && (totalPrice && paymentDue < totalPrice) && (
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              運費折扣
              <div>- NT$ {numberWithCommas(shippingDiscount)}</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              商品折扣
              <div>- NT$ {numberWithCommas(totalPrice - paymentDue - shippingDiscount)}</div>
            </div>
          </div>
        )}
        {
          shipping &&
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              總金額
              <div>NT$ {numberWithCommas(paymentDue || totalPrice)}</div>
            </div>
        }
      </div>
      {
        !isMobile && 
          <div
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              top: 0,
              background: "#D2C0AE",
              width: "300%",
              zIndex: -1,
            }}
          />
      }
    </div>
  );
}

const Cart = () => {
  const { isMobile } = useIsMobile()
  const { checkout, checkoutId, initialized, vacation } = useContext(CartContext)
  const { countries } = useContext(LayoutContext);
  const [phone, setPhone] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [province, setProvince] = useState("")
  const [address2, setAddress2] = useState("")
  const [postCode, setPostCode] = useState("")
  const [coupon, setCoupon] = useState("")
  const [company, setCompany] = useState("")
  const [shipping, setShipping] = useState(null)
  const [shippingOptions, setShippingOptions] = useState([])
  const [dialCode, setDialCode] = useState("+886")
  const [country, setCountry] = useState("Taiwan")
  const [city, setCity] = useState(null)
  const [cityList, setCityList] = useState(null)
  const [district, setDistrict] = useState(null)
  const [districtList, setDistrictList] = useState(null)
  const [paymentDue, setPaymentDue] = useState(null)
  const [shippingDiscount, setShippingDiscount] = useState(0)
  const [errorMessage, setErrorMessage] = useState('');
  const [orderLoading, setOrderLoading] = useState(false);
  const [addressReady, setAddressReady] = useState(false);

  const [isSummaryExpanded, setIsSummaryExpanded] = useState(false)

  const CVS_CHECKOUT_CACHE_KEY = 'CVS_CHECKOUT_CACHE';

  useEffect(() => {
    if (!checkout) {
      return;
    }
    if (checkout.paymentDue) {
      setPaymentDue(checkout.paymentDue);
    }
  }, [checkout]);

  const validate = () => {
    if (!firstName || !lastName) {
      return '請確實填寫姓名';
    }
    if (!phone) {
      return '請確實填寫手機號碼';
    }
    if (!email) {
      return '請確實填寫 Email';
    }
    if (
      country === "Taiwan" &&
      !shipping?.title.includes("超商") && (
        !address || !city || !postCode
    )) {
      return '請確實填寫收件資料';
    }
    return '';
  }

  const submit = async () => {
    const error = validate();
    if (error) {
      toast.error(error);
      setOrderLoading(false);
      return;
    }
    await updateAddressToShopify()
    order()
  }

  useEffect(() => {
    if (errorMessage) {
      setErrorMessage('');
    }
  }, [phone, firstName, lastName, email, address, city, shipping, district, postCode])

  useEffect(() => {
    if (checkoutId) {
      const cvscache = sessionStorage.getItem(CVS_CHECKOUT_CACHE_KEY);
      const shippingAddress = {
        address1: "合作咖啡",
        address2: "",
        city: "台中市",
        company: "",
        firstName: "",
        lastName: "合作咖啡",
        phone: "",
        province: "",
        country: "Taiwan",
        zip: "",
      };
      if (cvscache) {
        const cache = JSON.parse(cvscache);
        //shippingAddress.firstName = cache.firstName;
        setFirstName(cache.firstName);

        //shippingAddress.lastName = cache.lastName;
        setLastName(cache.lastName);

        //shippingAddress.phone = cache.phone;
        setPhone(cache.phone);

        setEmail(cache.email);
        setShipping(cache.shipping);

        setCoupon(cache.coupon);
        const query =
            typeof window !== 'undefined' ?
            queryString.parse(window.location.search) : {};
        if (query.company) {
          setCompany(query.company);
        }
        return;
      }
      client.request(
        `
        mutation checkoutShippingAddressUpdateV2(
          $checkoutId: ID!, $shippingAddress: MailingAddressInput!
        ) {
          checkoutShippingAddressUpdateV2(
            checkoutId: $checkoutId, shippingAddress: $shippingAddress
          ) {
            checkout {
              id
              availableShippingRates {
                  ready
                  shippingRates {
                      handle
                      price {
                        amount
                      }
                      title
                  }
              }
            }
            checkoutUserErrors {
              message
            }
          } 
          checkoutDiscountCodeRemove(checkoutId: $checkoutId) {
            checkout {
                totalPrice {
                        amount
                }
            }
          }
        }                             
      `,
        {
          checkoutId,
          shippingAddress,
        },
      ).then(data => {
       const rates =
           data.checkoutShippingAddressUpdateV2.checkout.availableShippingRates
             .shippingRates
         setShippingOptions(rates)
       })
    }
  }, [checkoutId]),

  useEffect(() => {
    if (country === "Taiwan") {
      const cities = taiwanDistricts.reduce((r, { name }) => [...r, name], [])
      setCityList(cities)
      setCity(cities[0])
    } else {
      setCityList(null)
      setDistrictList(null)
      setCity(null)
      setPostCode("")
      setDistrict(null)
    }
  }, [country])

  useEffect(() => {
    if (country === "Taiwan" && city) {
      const districts = taiwanDistricts.find(
        ({ name }) => name === city,
      ).districts
      setDistrictList(districts.map(d => d.name))
      setDistrict(districts[0].name)
    }
  }, [city])

  useEffect(() => {
    if (country === "Taiwan" && district) {
      const districts = taiwanDistricts.find(
        ({ name }) => name === city,
      ).districts
      const d = districts.find(({ name }) => name === district)
      setPostCode(d.zip)
    }
  }, [district])

  useEffect(() => {
    if (shipping) {
      updateAddressToShopify()
    }
  }, [shipping])

  useEffect(() => {
  }, [postCode, province, address, address2, city])

  const items = checkout?.items || []

  const itemsPrice = useMemo(() => {
    return items.reduce((r, v) => {
      return r + v.price
    }, 0)
  }, [items])

  const shippingPrice = useMemo(() => {
    if (!shipping) return 0
    return parseInt(shipping.price.amount)
  }, [shipping])

  const totalPrice = useMemo(() => {
    return shippingPrice + itemsPrice
  }, [itemsPrice, shippingPrice])

  const selectCVS = async () => {
    var form = document.createElement("form")
    form.style.visibility = "hidden"
    form.method = "POST"
    form.action = "https://logistics.ecpay.com.tw/Express/map"
    const cvsp = {
      MerchantID: "3285192",
      LogisticsType: "CVS",
      LogisticsSubType: shipping.title.includes("7-11")
        ? "UNIMART"
        : shipping.title.includes("全家")
        ? "FAMI"
        : "",
      IsCollection: "N",
      ServerReplyURL: `https://workers.cooperation.coffee/cvs-order?checkout_url=${window.location.origin}${window.location.pathname}`
    }
    for (const key in cvsp) {
      var input = document.createElement("input")
      input.name = key
      input.value = cvsp[key]
      form.appendChild(input)
    }
    sessionStorage.setItem(CVS_CHECKOUT_CACHE_KEY, JSON.stringify({
      firstName,
      lastName,
      email,
      phone,
      coupon,
      shipping,
    }));
    document.body.appendChild(form)
    form.submit()
  }

  const order = async () => {
    const isStage = document.location.host !== "cooperation.coffee"
    const resp = await fetch(
      `https://workers.cooperation.coffee/order?${
        isStage ? "stage=true&" : ""
      }${coupon ? `code=${coupon}&` : ""}`,
      {
        method: "POST",
        body: JSON.stringify({
          id: checkoutId,
        }),
      },
    )
    const payload = await resp.json()

    var form = document.createElement("form")
    form.style.visibility = "hidden"
    form.method = "POST"
    form.action = isStage
      ? "https://payment-stage.ecpay.com.tw/Cashier/AioCheckOut/V5"
      : "https://payment.ecpay.com.tw/Cashier/AioCheckOut/V5"
    for (const key in payload) {
      var input = document.createElement("input")
      input.name = key
      input.value = payload[key]
      form.appendChild(input)
    }
    document.body.appendChild(form)
    form.submit()
  }

  const updateAddressToShopify = () => {
    const shippingAddress = {
      address1: address || "地址1",
      address2: address2 || "地址2",
      city: city || "台中",
      company: company || "",
      firstName: firstName || "合作",
      lastName: lastName || "咖啡",
      phone: 
        `${dialCode}${phone.replace(/^\+886/, "")}`.replace(/^\+88609/, "+8869"),
      province: province || "",
      country: country,
      zip: postCode || "000",
    };

    if (district) {
      shippingAddress.address1 = shippingAddress.address1 + ' ' + district;
    }

    if (shipping?.title?.includes('超商') && company) {
      shippingAddress.address1 = company;
      const query =
          typeof window !== 'undefined' ?
          queryString.parse(window.location.search) : {};
      if (query.address1) {
        shippingAddress.address2 = query.address1;
      }
    }
  
    return client.request(
      `
          mutation checkoutShippingAddressUpdateV2(
            $checkoutId: ID!, 
						$shippingAddress: MailingAddressInput!,
						${ shipping?.handle ? '$shippingRateHandle: String!' : ''}
            $email: String!
          ) {
            checkoutShippingAddressUpdateV2(
              checkoutId: $checkoutId, shippingAddress: $shippingAddress
            ) {
              checkout {
                id
								availableShippingRates {
										ready
										shippingRates {
												handle
												price {
													amount
												}
												title
										}
								}
              }
              checkoutUserErrors {
                message
              }
            }
            ${ shipping?.handle ? `
              checkoutShippingLineUpdate(checkoutId: $checkoutId, shippingRateHandle: $shippingRateHandle) {
                checkout {
                  id
                  paymentDue {
                    amount
                  }
                  shippingDiscountAllocations {
                    allocatedAmount {
                      amount
                    }
                    discountApplication {
                      targetType
                    }
                  }
                }
                checkoutUserErrors {
                  code
                  field
                  message
                }
              }
            ` : ''}
            checkoutEmailUpdateV2(checkoutId: $checkoutId, email: $email) {
              checkout { id }
            }
          }                             
        `,
      {
        checkoutId,
        email,
        shippingAddress,
        shippingRateHandle: shipping?.handle,
      },
    ).then((data) => {
      if (data.checkoutShippingAddressUpdateV2.checkoutUserErrors.length > 0) {
        for (const e of data.checkoutShippingAddressUpdateV2.checkoutUserErrors) {
          toast.error(e.message);
        }
        setAddressReady(false);
      } else if (!addressReady) {
        setAddressReady(true);
      }
      if (data.checkoutShippingLineUpdate) {
        setPaymentDue(
          parseInt(data.checkoutShippingLineUpdate.checkout.paymentDue.amount),
        )
        const shippingDiscountAllocations = data.checkoutShippingLineUpdate.checkout.shippingDiscountAllocations;
        if (shippingDiscountAllocations.length > 0) {
          setShippingDiscount(shippingDiscountAllocations.reduce((r, v) => {
            return r + parseInt(v.allocatedAmount.amount)
          }, 0))
        }
      }
     const rates =
         data.checkoutShippingAddressUpdateV2.checkout.availableShippingRates
           .shippingRates
       setShippingOptions(rates)
    })
  }

  const applyDiscount = async (coupon) => {
    if (!shipping) {
      toast.error('請先選擇運送方式');
      return;
    } else if (!coupon) {
      toast.error('請輸入折扣碼');
      return;
    }
    setCoupon(coupon);
    await updateAddressToShopify()
    const data = await client.request(
      `
						mutation checkoutDiscountCodeApplyV2($checkoutId: ID!, $discountCode: String!) {
							checkoutDiscountCodeApplyV2(checkoutId: $checkoutId, discountCode: $discountCode) {
								checkout {
										totalPrice {
									  	amount
										}
										paymentDue {
											amount
										}
                    shippingDiscountAllocations {
                      allocatedAmount {
                        amount
                      }
                      discountApplication {
                        targetType
                      }
                    }
								}
							}
						}
        `,
      {
        checkoutId,
        discountCode: coupon,
      },
    )
    setPaymentDue(
      parseInt(data.checkoutDiscountCodeApplyV2.checkout.paymentDue.amount),
    )
    const shippingDiscountAllocations = data.checkoutDiscountCodeApplyV2.checkout.shippingDiscountAllocations;
    if (shippingDiscountAllocations.length > 0) {
      setShippingDiscount(shippingDiscountAllocations.reduce((r, v) => {
        return r + parseInt(v.allocatedAmount.amount)
      }, 0))
    }
  }


  return (
    <div
      style={{
        ...(!isMobile && {
          minHeight: 'calc(100vh - 247px)'
        }),
      }}
    >
      <div
        style={{
          display: "flex",
          margin: "0px auto",
          justifyContent: "center",
          padding: '0px 48px',
          alignItems: "stretch",
          overflowX: "hidden",
          ...(isMobile
            ? {
                flexDirection: "column",
                gap: "0px",
                padding: "0px",
              }
            : {}),
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: '48px 60px 48px 0px',
            width: '700px',
            ...(isMobile && {
              width: "100%",
              padding: "24px",
            }),
          }}
        >
          <div
            className="title3"
            style={{
              marginBottom: "8px",
            }}
          >
            Information
          </div>
          <div className="title2"
            style={{
              marginBottom: "24px",
            }}
          >
            購物資訊
          </div>
          {
            isMobile && <div style={{ display: "flex", flexDirection: "column" }}>
              <div 
                style={{ 
                  background: '#f8f5f3', 
                  width: 'calc(100% + 48px)',
                  margin: '0px -24px 24px -24px',
                  ...(isSummaryExpanded && {
                    margin: '0px -24px 0px -24px',
                  }),
                  padding: '12px 24px',
                  borderTop: "1px solid #d7d5d3",
                  borderBottom: "1px solid #d7d5d3",
                  display: 'flex',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setIsSummaryExpanded(!isSummaryExpanded);
                }}
              >
                <div style={{ 
                  marginRight: 8,
                  marginTop: -2,
                  }}
                >
                  {
                    isSummaryExpanded ? 
                      <AiFillCaretDown /> :
                      <AiFillCaretUp />
                  }
                </div>
                購物明細及折扣碼
                <div style={{ flexGrow: 1 }} />
                <div>NT$ {numberWithCommas(paymentDue || totalPrice)}</div>
              </div>
              {
                isSummaryExpanded && <Summary 
                  items={items}
                  itemsPrice={itemsPrice}
                  shipping={shipping}
                  shippingPrice={shippingPrice}
                  shippingDiscount={shippingDiscount}
                  paymentDue={paymentDue}
                  totalPrice={totalPrice}
                  onApply={(coupon) => applyDiscount(coupon)}
                />
              }
            </div>
          }
          <div style={{ marginBottom: 8}}>收件人姓名</div>
          <div style={{ display: "flex", gap: 8 }}>
            <Input
              autoComplete="last-name"
              placeholder="姓"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            />
            <Input
              autoComplete="first-name"
              placeholder="名"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />
          </div>
          <div style={{ marginTop: 24, marginBottom: 8 }}>收件人手機號碼</div>
          <Input
            prefix={dialCode}
            value={phone}
            autoComplete="phone"
            onChange={e => setPhone(e.target.value)}
          />
          <div style={{ marginTop: 24, marginBottom: 8 }}>Email</div>
          <Input
            autoComplete="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <div style={{ marginTop: 24, marginBottom: 8 }}>國家</div>
          <select
            style={{ 
              padding: '4px 12px', 
              minWidth: 0, 
              borderRadius: 4,
              background: "white",
              border: "1px solid #d7d5d3",
              width: '100%',
              height: '42px',
            }}
            value={country}
            onChange={e => {
              setCountry(e.target.value)
              const isoCode = countries.find(c => c.name === e.target.value).isoCode;
              const c = countriesData.find((c) => c.code === isoCode);
              setDialCode(c?.dialCode ?? '');
            }}
          >
            {countries.map((v) => {
              return (
                <option value={v.name} key={v.name}>
                  {v.name}
                </option>
              )
            })}
          </select>
          {country !== "Taiwan" && (
              <div>
                <div style={{ marginTop: 16, display: "flex", gap: 8 }}>
                  <Input
                    placeholder="Province"
                    value={province || ""}
                    onChange={e => setProvince(e.target.value)}
                    autoComplete="province"
                  />
                  <Input
                    placeholder="City"
                    value={city || ""}
                    onChange={e => setCity(e.target.value)}
                    autoComplete="city"
                  />
                  <div style={{ width: 200 }}>
                    <Input
                      value={postCode}
                      onChange={e => setPostCode(e.target.value)}
                      autoComplete="postal-code"
                      placeholder="Zip"
                    />
                  </div>
                </div>
                <div style={{ display: "flex", gap: 8, marginTop: 16 }}>
                  <Input
                    value={address}
                    placeholder="Address 1"
                    onChange={e => setAddress(e.target.value)}
                    autoComplete="street-address"
                  />
                </div>
                <div style={{ display: "flex", gap: 8, marginTop: 16 }}>
                  <Input
                    value={address2}
                    placeholder="Address 2"
                    onChange={e => setAddress2(e.target.value)}
                    autoComplete="street-address2"
                  />
                </div>
                {
                  country !== 'Taiwan' && !addressReady &&
                    <div
                      className="my-btn"
                      onClick={() => {
                        updateAddressToShopify();
                      }}
                      style={{
                        border: "1px solid #000",
                        padding: "8px 24px",
                        textAlign: "center",
                        cursor: "pointer",
                        height: "fit-content",
                        borderRadius: 4,
                        marginTop: 24,
                      }}
                    >
                      Check Available Shipping
                    </div>
                }
              </div>
            )}
          {(country === "Taiwan" || addressReady) && (
            <div
              style={{
                marginTop: 24,
                display: "flex",
                flexDirection: 'column'
              }}
            >
              <div>運送方式</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: 'column',
                  marginTop: '8px',
                  border: "1px solid #d7d5d3",
                  background: "white",
                  borderRadius: 4,
                }}
              >
                {
                  shippingOptions.map((v, i) => (<div
                    style={{
                      padding: '6px 12px',
                      ...(i !== (shippingOptions.length - 1) && {
                        borderBottom: "1px solid #d7d5d3",
                      }),
                      background: "transparent",
                      display: "flex",
                      alignItems: 'center'
                    }}
                  >
                    <Radio
                      selected={shipping?.title}
                      style={{ 
                        margin: 8,
                        marginRight: 12,
                      }}
                      value={v.title}
                      onChange={e => {
                        setShipping(v);
                        setCompany(null);
                      }}
                    />
                    {v.title}
                  </div>)
                  )
                }
              </div>
            </div>
          )}
          {shipping && country === "Taiwan" && 
            !shipping.title.includes("超商") &&
            !shipping.title.includes("自取") && (
              <div>
                <div style={{ marginTop: 16, display: "flex", gap: 8 }}>
                  {cityList ? (
                    <select
                      style={{ 
                        padding: 4, 
                        borderRadius: 4,
                        background: "white",
                        border: "1px solid #d7d5d3",
                      }}
                      value={city}
                      onChange={e => {
                        setCity(e.target.value)
                      }}
                    >
                      {cityList.map((v, i) => {
                        return (
                          <option value={v} key={v}>
                            {v}
                          </option>
                        )
                      })}
                    </select>
                  ) : (
                    <div>
                      <Input
                        placeholder="城市"
                        value={city || ""}
                        onChange={e => setCity(e.target.value)}
                        autoComplete="city"
                      />
                    </div>
                  )}
                  {districtList ? (
                    <select
                      style={{ 
                        padding: 4, 
                        borderRadius: 4,
                        background: "white",
                        border: "1px solid #d7d5d3",
                      }}
                      value={district || ""}
                      onChange={e => {
                        setDistrict(e.target.value)
                      }}
                    >
                      {districtList.map((v, i) => {
                        return (
                          <option value={v} key={v}>
                            {v}
                          </option>
                        )
                      })}
                    </select>
                  ) : (
                    <Input
                      placeholder="區"
                      value={district || ""}
                      onChange={e => setDistrict(e.target.value)}
                      autoComplete="district"
                    />
                  )}
                  <Input
                    value={postCode}
                    onChange={e => setPostCode(e.target.value)}
                    autoComplete="postal-code"
                    placeholder="郵遞區號"
                  />
                </div>
                <div style={{ display: "flex", gap: 8, marginTop: 16 }}>
                  <Input
                    value={address}
                    placeholder="地址欄一"
                    onChange={e => setAddress(e.target.value)}
                    autoComplete="street-address"
                  />
                </div>
                <div style={{ display: "flex", gap: 8, marginTop: 16 }}>
                  <Input
                    value={address2}
                    placeholder="地址欄二 (選填)"
                    onChange={e => setAddress2(e.target.value)}
                    autoComplete="street-address2"
                  />
                </div>
              </div>
            )}
          <div style={{ marginTop: 24 }} />
          {
            shipping &&
              country === "Taiwan" &&
              shipping.title.includes("超商") && 
              <>
                <div style={{ marginBottom: 8 }}>取貨資料</div>
                {
                  company ? <div style={{ marginBottom: '24px'}}>
                    {company}
                  </div> :
                  <div
                    style={{
                      border: '1px solid #d7d5d3',
                      borderRadius: 4,
                      height: 42,
                      background: 'white',
                      padding: '4px 8px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      cursor: 'pointer',
                      marginBottom: '24px',
                    }}
                    onClick={() => {
                      selectCVS();
                    }}
                  >
                      請選擇門市
                      <AiFillCaretDown />
                  </div> 
                }
              </>
          }
          {
            shipping && (shipping?.title.includes('超商') ? company : true) &&
            <div
              className="my-btn"
              onClick={() => {
                if (vacation) {
                  alert(vacation);
                  return;
                }
                if (orderLoading) {
                  return;
                }
                setOrderLoading(true);
                submit();
              }}
              style={{
                border: "1px solid #000",
                padding: "8px 24px",
                textAlign: "center",
                cursor: "pointer",
                height: "fit-content",
                borderRadius: 4,
                ...(orderLoading && {
                  background: 'transparent'
                })
              }}
            >
              {
                orderLoading ? 
                  <LoadingIcon /> : 
                  "結帳"
              }
            </div>
          }
          {
            errorMessage && <div
              style={{ marginTop: '8px', color: '#9D2933' }}
            >
              {errorMessage}
            </div>
          }
        </div>
        <div style={{ width: 'auto' }} />
        {
          !isMobile && 
            <Summary 
              items={items}
              itemsPrice={itemsPrice}
              shipping={shipping}
              shippingPrice={shippingPrice}
              shippingDiscount={shippingDiscount}
              paymentDue={paymentDue}
              totalPrice={totalPrice}
              onApply={(coupon) => applyDiscount(coupon)}
            />
        }
      </div>
    </div>
  )
}

export default Cart
export const Head = () => (
  <SEO
    title="結帳"
  />
)
